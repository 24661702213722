@import 'https://library-sdb.apps.bancolombia.com/bds/6.54.4/bds.min.css';


/* Color Variable */
$body-color: #292929;
$heading-color: #333333;
$white-color: #FFFFFF;
$page-title-bg: #0d3642;
$green-color: #fb7a00;

/* Font Variable */
$body-font: 'Montserrat', sans-serif;
$heading-font: 'Poppins', sans-serif;

//estilos para ubicar el footer al fondo
html, body {
  height: 100%;
  margin: 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content {
  flex: 1;
}

.bc-stepper-step {
  pointer-events: none;
}

ngx-extended-pdf-viewer {
  display: block;
}
.bc-header {
  background-color: inherit !important;
}

.bc-container-checkbox {
  margin-top: 30px;
  display: table;
}

.radio-firma{
  .bc-radio-group{
    display: flex;
  }
}

body {
  background-color: #f4f4f4;
}

img {
  max-width: 100%;
}

@media(min-width:1170px) {

  article.bc-stepper-horizontal[role=tablist][aria-labelledby] section.bc-stepper-step .bc-stepper-label label {
    color: #2C2A29;
    top: 48px;
    left: -40px;
    width: 240px;
  }

  article.bc-stepper-horizontal[role=tablist][aria-label] section.bc-stepper-step .bc-stepper-label label,
  article.bc-stepper-horizontal[role=tablist][aria-labelledby] section.bc-stepper-step .bc-stepper-label label {
    color: #2C2A29;
    top: 48px;
    left: -105px;
    width: 240px;
  }

  article.bc-stepper-horizontal[role=tablist][aria-label] section.bc-stepper-step:last-of-type .bc-stepper-label label,
  article.bc-stepper-horizontal[role=tablist][aria-labelledby] section.bc-stepper-step:last-of-type .bc-stepper-label label {
    color: #2C2A29;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    left: -210px;
    text-align: right;
  }
}
//ver titulo del item completo de 359 en adelante
@media screen and (min-width: 359px){
  .bc-stepper .bc-stepper-header .bc-stepper-header-center h6 {
    width: auto;
  }
  .bc-stepper .bc-stepper-header .bc-stepper-header-center p {
    width: 200px;
  }
}

a{
  font-size: 14px;
}

ul{
  padding-left: 1rem;
}
h6 {
  margin-bottom: 0;
}
.potr{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-left: initial;
    font-size: 17px;
    a{
        text-decoration: revert;
        color: #292929;
    }
}

.button-cancelar {
	display: flex;
	justify-content: center;
	text-decoration: underline;
	text-underline-offset: 3px;
	cursor: pointer;

	a {
		color: #2C2A29;
	}

}

div.bc-accordions-group.bc-multiple[role=group] article.bc-accordion,
div.bc-accordions-group[role=group] article.bc-accordion {
  border-radius: 8px;
}

.bc-form-field {
  margin-top: 25px;
}

.bc-button-fill .bc-button-primary .bc-button-small{
  width: 350px;
}
/** -------------- Modificar estilo pordefecto del banco--------------**/

.bc-modal-close-button {
  border: none;
  background: white;
}

.bc-modal-container {
  height: 490px;

  .bc-modal-content {
    max-height: inherit !important;
  }
}


.bc-icon-button-outline{
  border: 1px solid black !important;
}

section.bc-modal-container[customWidth=medium] section.bc-modal-button-container button {
  width: 20px;
}
section button.bc-alert-close>em {
  display: none;
}

.contenedor-modal{
  section.bc-modal-container[customWidth=small] section.bc-modal-button-container {
    display: none;
  }
}

@media(min-width:1170px){

  article.bc-stepper-horizontal[role=tablist][aria-label] section.bc-stepper-step .bc-stepper-label label, article.bc-stepper-horizontal[role=tablist][aria-labelledby] section.bc-stepper-step .bc-stepper-label label {
    color: #2C2A29;
    top: 48px;
    left: -105px;
    width: 240px;
  }

  article.bc-stepper-horizontal[role=tablist][aria-label] section.bc-stepper-step:last-of-type .bc-stepper-label label, article.bc-stepper-horizontal[role=tablist][aria-labelledby] section.bc-stepper-step:last-of-type .bc-stepper-label label {
    color: #2C2A29;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    left: -210px;
    text-align: right;
  }
}

@media screen and (max-width: 576px) {
  .bc-stepper .bc-stepper-header .bc-stepper-header-center h6 {
    width: 160px;
  }

  section.bc-modal-button-container {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
}


.bc-progress-container {
  width: 280px;
  margin: auto;
}

section button.bc-alert-close>em {
  display: none;
}

.bc-tooltip-container {
  max-width: 250px;
  }


section.bc-modal-container[customWidth=medium] {
  width: 500px;
  height: auto;
}

/** -------------- Fin modificar estilo pordefecto del banco--------------**/


/* Footer Section CSS Start */
.footer-area {
  margin-top: 5%;

  .copyright-area {
    border-top: 1px solid #CCC;


    p {
      color: #000;
      margin-bottom: 0;

    }

  }
}

/* Footer Section CSS End */
/** -------------- Home Page One CSS End --------------**/


/**-------------- Pre Loader CSS Start --------------**/
.loader-content {
  background: $white-color;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999999;
  left: 0;
  top: 0;
  text-align: center;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid red;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/**-------------- Pre Loader CSS End --------------**/

/* Magnific Popup */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* Animation CSS */
@keyframes icon-animation {
  0% {
    border-radius: 46% 54% 68% 32% / 55% 48% 52% 45%;
  }

  50% {
    border-radius: 66% 34% 41% 59% / 51% 34% 66% 49%;
  }

  100% {
    border-radius: 46% 54% 68% 32% / 55% 48% 52% 45%;
  }
}

@keyframes wrep {
  0% {
    border-radius: 55% 45% 38% 62% / 52% 57% 43% 48%;
  }

  35% {
    border-radius: 67% 33% 39% 61% / 48% 63% 37% 52%;
  }

  70% {
    border-radius: 40% 60% 62% 38% / 32% 41% 59% 68%;
  }

  100% {
    border-radius: 55% 45% 38% 62% / 52% 57% 43% 48%;
  }
}

@keyframes translatex {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(20px);
  }

  10% {
    transform: translateX(0px);
  }
}

@keyframes translatey {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15px);
  }

  10% {
    transform: translateY(0px);
  }
}

/* play button animation */
@keyframes pulse {
  from {
    transform: scale(1);
    filter: opacity(0.9);
  }

  to {
    transform: scale(2);
    filter: opacity(0);
  }
}

@media screen and (min-width: 359px){
  .bc-stepper .bc-stepper-header .bc-stepper-header-center h6 {
    width: auto;
  }
}
