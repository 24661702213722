@media (max-width:767px) {

  .about-title {
      margin-top: 25%;
  }


    /*------ 404 Page CSS Start ------*/
    .error-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }
    /*------ 404 Page CSS End ------*/

}

@media (min-width:768px) and (max-width:991px) {

    /*------ 404 Page CSS Start ------*/
    .error-title {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;

        h2 {
            font-size: 30px !important;
        }
    }
    /*------ 404 Page CSS End ------*/

}
